.dedicated-customer {
  background: #212E5E;
  padding-top: 562px;
  padding-bottom: 300px;

  @media (max-width: 768px) {
    padding-top: 463px;
  }

  @media (max-width: 600px) {
    position: relative;
    padding-bottom: 100px;
  }

  .container-page {
    position: relative;
    margin: inherit;
    max-width: 100%;

    @media (max-width: 600px) {
      position: initial;
    }
  }

  .img-card {
    display: flex;
    align-items: center;
    position: absolute;
    top: -820px;
    width: 100%;
    left: -8px;
    max-width: 1280px;

    @media (max-width: 768px) {
      top: -870px;
      left: 0px;
    }

    @media (max-width: 600px) {
      top: -409px;
    }

    img {
      width: 100%;
      max-width: 915px;
      height: 670px;
      object-fit: cover;

      @media (max-width: 600px) {
        width: 100%;
        height: 450px;
      }    
    }

    .container-card {
      padding: 70px 50px 73px;
      background: #2D5397;
      width: 455px;
      position: absolute;
      right: 80px;

      @media (max-width: 1000px) {
        right: 0;
      }

      @media (max-width: 768px) {
        padding: 50px 30px 55px 40px;
        top: calc(100% - 290px);
        width: 345px;
      }

      @media (max-width: 600px) {
        top: calc(100% - 150px);
      }

      .top-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
        color: #DAE2ED;
        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 20px;
        }
      }

      .title {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 700;
        color: #fff;
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 12px;
        text-transform: capitalize;

        @media (max-width: 768px) {
          font-size: 30px;
          line-height: 36px;
        }
      }

      .description {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 35px;
        color: #DAE2ED;

        @media (max-width: 768px) {
          font-size: 15px;
          line-height: 23px;
        }
      }

      .btn-orange {
        background: linear-gradient(270deg, #E75E24 0%, #F9893D 100%);
        border-radius: 30px;
        width: 157px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;

        &:hover {
          background: linear-gradient(270deg, #f9893d 0%, #F9893D 100%);

        }
      }
    }
  }

  .container-customer {
    max-width: 740px;
    margin: auto;

    .top-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #DAE2ED;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px;

      @media (max-width: 600px) {
        font-size: 15px;
        line-height: 20px;
      }
    }

    .title {
      font-family: 'Archivo';
      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      text-align: center;
      color: #fff;
      line-height: 52px;
      margin-bottom: 15px;

      @media (max-width: 600px) {
        font-size: 36px;
        line-height: 43px;
      }
    }

    .description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #DAE2ED;
      margin-bottom: 41px;

      @media (max-width: 600px) {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 45px;
      }
    }

    .container-numbers {
      display: flex;
      justify-content: space-between;

      @media (max-width: 603px) {
        flex-direction: column;
      }

      .numbers {
        margin-right: 10px;

        @media (max-width: 603px) {
          margin-right: 0;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        
        &:last-child {
          margin-right: 0;
        }

        .number {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 700;
          font-size: 80px;
          line-height: 87px;
          color: #F9893D;
          margin-bottom: 10px;
          justify-content: center;
          display: flex;
          align-items: center;

          span {
            font-size: 40px;
          }

          @media (max-width: 600px) {
            font-size: 60px;
            line-height: 65px;
          }

          svg {
            margin-left: 10px;

            path {
              stroke: #F9893D;
            }
          }
        }

        .subtitle {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 600;
          display: block;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          color: #fff;

          @media(max-width:768px){
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.services-template-default {
  .hero-solid {
    min-height: 700px;

    @media(max-width: 768px){
      min-height: 663px;
    }
  }


  .dedicated-customer {
    padding: 0;

    .img-card {
      top: -300px;
    }
  }

  .container-certification {
    padding-top: 427px;

    @media (max-width: 768px) {
      padding-top: 517px;
    }

    @media (max-width: 600px) {
      padding-top: 377px;
    }

    @media (max-width: 560px) {
      padding-top: 500px;
    }
  }

  .about-project {
    .container-text {
      @media(max-width: 500px){
        top: -635px;
      }
    }
  }
}

.boiler-code-welding  .container-certification {
  padding-top: 158px;
}
